import { StateCreator, StoreMutatorIdentifier } from 'zustand'
import { StateStorage } from 'zustand/middleware'

export const isClient = () => typeof window !== 'undefined'

type LoggerType = <
  T,
  Mps extends [StoreMutatorIdentifier, unknown][] = [],
  Mcs extends [StoreMutatorIdentifier, unknown][] = [],
>(
  f: StateCreator<T, Mps, Mcs>,
  name?: string,
) => StateCreator<T, Mps, Mcs>

type LoggerImplType = <T>(f: StateCreator<T, [], []>, name?: string) => StateCreator<T, [], []>

const loggerImpl: LoggerImplType = (f, name) => (set, get, store) => {
  if (process.env.NODE_ENV === 'development' && isClient()) {
    const loggedSet: typeof set = (...a) => {
      set(...a)
      // eslint-disable-next-line no-console
      console.log(...(name ? [`${name}:`] : []), get())
    }
    Object.assign(store, { setState: loggedSet })
  }

  return f(store.setState, get, store)
}

export const logger = loggerImpl as unknown as LoggerType

export const defaultStorage: StateStorage = {
  getItem: () => null,
  setItem: () => {},
  removeItem: () => {},
}

export const sliceResetFns = new Set<() => void>()

export const resetAllSlices = () => {
  // eslint-disable-next-line sonarjs/no-empty-collection
  sliceResetFns.forEach((reset) => {
    reset()
  })
}
